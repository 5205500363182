// Generated by Framer (abcfa95)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./IjOVbwal9-0.js";

const cycleOrder = ["uK4Dasweq"];

const serializationHash = "framer-5hPZt"

const variantClassNames = {uK4Dasweq: "framer-v-104dord"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, OJw91V9Lh: click ?? props.OJw91V9Lh} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, OJw91V9Lh, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "uK4Dasweq", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1nzdqi9 = activeVariantCallback(async (...args) => {
if (OJw91V9Lh) {const res = await OJw91V9Lh(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-104dord", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"uK4Dasweq"} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", intrinsicHeight: 917, intrinsicWidth: 1218, pixelHeight: 1834, pixelWidth: 2436, sizes: "210px", src: "https://framerusercontent.com/images/c5R1FaqwXCqjwU8hlfygclDNXk.png?scale-down-to=2048", srcSet: "https://framerusercontent.com/images/c5R1FaqwXCqjwU8hlfygclDNXk.png?scale-down-to=512 512w,https://framerusercontent.com/images/c5R1FaqwXCqjwU8hlfygclDNXk.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/c5R1FaqwXCqjwU8hlfygclDNXk.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/c5R1FaqwXCqjwU8hlfygclDNXk.png 2436w"}} className={"framer-itho8t"} data-framer-name={"image"} data-highlight layoutDependency={layoutDependency} layoutId={"tSmrdla2W"} onTap={onTap1nzdqi9} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7T3BlbiBTYW5zLTYwMA==", "--framer-font-family": "\"Open Sans\", \"Open Sans Placeholder\", sans-serif", "--framer-font-weight": "600", "--framer-text-color": "var(--extracted-r6o4lv, rgb(68, 68, 68))"}}>Stilla skilyrði auglýsingar</motion.p></React.Fragment>} className={"framer-cs8dog"} fonts={["GF;Open Sans-600"]} layoutDependency={layoutDependency} layoutId={"B44q4KHQG"} style={{"--extracted-r6o4lv": "rgb(68, 68, 68)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5hPZt.framer-1no53c1, .framer-5hPZt .framer-1no53c1 { display: block; }", ".framer-5hPZt.framer-104dord { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 10px 0px 0px 0px; position: relative; width: min-content; }", ".framer-5hPZt .framer-itho8t { aspect-ratio: 1.3282442748091603 / 1; cursor: pointer; flex: none; height: var(--framer-aspect-ratio-supported, 158px); overflow: visible; position: relative; width: 210px; }", ".framer-5hPZt .framer-cs8dog { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5hPZt.framer-104dord { gap: 0px; } .framer-5hPZt.framer-104dord > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-5hPZt.framer-104dord > :first-child { margin-top: 0px; } .framer-5hPZt.framer-104dord > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 197.5
 * @framerIntrinsicWidth 210
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"OJw91V9Lh":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerIjOVbwal9: React.ComponentType<Props> = withCSS(Component, css, "framer-5hPZt") as typeof Component;
export default FramerIjOVbwal9;

FramerIjOVbwal9.displayName = "Screenshot";

FramerIjOVbwal9.defaultProps = {height: 197.5, width: 210};

addPropertyControls(FramerIjOVbwal9, {OJw91V9Lh: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerIjOVbwal9, [{explicitInter: true, fonts: [{family: "Open Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4iY1M2xLER.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})